#question-and-answer {
    padding: 20px 50px;
}

#question-and-answer .title {
    font-size: 25px;
    text-align: center;
}

.font-weight-text {
    font-weight: 400;
}

.s1 {
    color: red;
}

.p9 {
    color: red;
}

.p1 {
    color: #0070c0;
}

.text-color-primary {
    color: #0070c0;
}

.s3 {
    color: #0070c0;
}

.p6 {
    color: #0070c0;
    font-size: 18px;
}

.p5 {
    color: #0070c0;
    font-size: 20px;
}