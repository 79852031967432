.menu-sidebar {
    z-index: 3;
    background-color: #fff !important;
    box-shadow: 0px 3px 3px #ccc;
    padding: 5px;
}

.sidebar-required .numberCircle {
 background-color: #ccc !important;
}

.sidebar-required {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #ccc !important;
    border-bottom: 1px solid gray;
}

/*.menu-sidebar #basic-navbar-nav {*/
/*    margin-left: 75px;*/
/*}*/

.menu-sidebar .dropdown-menu .menu-item {
    padding: 0.5rem;
}

.menu-sidebar .menu-item {
    color: rgba(0, 0, 0, .6) !important;
    text-transform: uppercase;
    font-size: 15px;
    margin: 0px 3px;
    display: block;
    padding: 0 0.5rem;
}

.menu-sidebar .menu-item .dropdown > a {
    color: rgb(0, 0, 0, 0.6) !important;
}

.menu-sidebar .menu-item .dropdown a:hover {
    color: #007c30 !important;
}

.menu-sidebar .menu-item:hover {
    font-weight: bolder;
    color: #007c30 !important;
    text-decoration: none;
}

.menu-sidebar .menu-selected {
    font-weight: bolder;
    color: #007c30 !important;
    text-shadow: 0px 1px 1px #ccc;
}

.menu-sidebar .menu-item.menu-selected-dropItem {
    font-weight: bolder;
    color: #007c30 !important;
    text-shadow: 0px 1px 1px #ccc;
}

.menu-sidebar .menu-selected::after {
    transition: 0.7s;
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -5px;
    background-color: #0A943F;
}

.menu-sidebar .inActive {
    cursor: none;
    pointer-events: none;
    color: rgb(87 83 83 / 50%) !important;
}

.menu-sidebar #basic-navbar-nav .numberCircle {
    margin: auto;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 0px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font: 10px Arial, sans-serif;
    top: 0px;
    left: -2px;
}

.menu-sidebar .menu-selected .numberCircle {
    color: #007c30 !important;
    border: 2px solid #007c30 !important;
}

.menu-sidebar .dropdown-expert {
    padding: 0;
}

.menu-sidebar #collasible-nav-dropdown {
    padding: 0;
}

.menu-sidebar #collasible-nav-dropdown:hover {
    color: #007c30 !important;
}

.menu-sidebar .menu-selected #collasible-nav-dropdown {
    font-weight: bolder;
    color: #007c30 !important;
    text-shadow: 0px 1px 1px #ccc;
}

.active-expert #collasible-nav-dropdown,
.active-pro-ref #collasible-nav-dropdown
{
    font-weight: bolder;
    color: #007c30 !important;
    text-shadow: 0px 1px 1px #ccc;
}

.menu-item.active-home {
    font-weight: bolder;
    color: #007c30 !important;
    text-shadow: 0px 1px 1px #ccc;
}

@media (max-width: 991px) {
    .menu-sidebar .menu-selected::after {
        display: none;
    }
}

.rainbow {
    text-decoration: none !important;
    text-transform: uppercase;
    font-size: 15px;
    margin: 0px 3px;
    display: block;
    padding: 0 0.5rem;
    /* Chrome, Safari, Opera */
    -webkit-animation: rainbow 10s infinite !important;

    /* Internet Explorer */
    -ms-animation: rainbow 5s infinite !important;

    /* Standar Syntax */
    animation: rainbow 5s infinite !important;
}

.rainbow:hover {
    color: #007c30 !important;
    animation: none !important;
    font-weight: bolder;
    text-shadow: 0px 1px 1px #ccc !important;
}

@-webkit-keyframes rainbow{
    0%{color: orange;}
    10%{color: purple;}
    20%{color: red;}
    30%{color: CadetBlue;}
    40%{color: yellow;}
    50%{color: coral;}
    60%{color: green;}
    70%{color: cyan;}
    80%{color: DeepPink;}
    90%{color: DodgerBlue;}
    100%{color: orange;}
}

@-ms-keyframes rainbow{
    0%{color: orange;}
    10%{color: purple;}
    20%{color: red;}
    30%{color: CadetBlue;}
    40%{color: yellow;}
    50%{color: coral;}
    60%{color: green;}
    70%{color: cyan;}
    80%{color: DeepPink;}
    90%{color: DodgerBlue;}
    100%{color: orange;}
}

@keyframes rainbow{
    0%{color: orange;}
    10%{color: purple;}
    20%{color: red;}
    30%{color: CadetBlue;}
    40%{color: yellow;}
    50%{color: coral;}
    60%{color: green;}
    70%{color: cyan;}
    80%{color: DeepPink;}
    90%{color: DodgerBlue;}
    100%{color: orange;}
}

/*@media (max-width: 768px) {*/
/*    .menu-sidebar #basic-navbar-nav .numberCircle {*/
/*        top : 19px;*/
/*    }*/
/*}*/
