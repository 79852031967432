header {
  background-image: linear-gradient(#1fc760 0%, #007c30 100%);
}

header .header-left .logo {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

header .header-left .logo img {
  width: 60px;
  height: 60px;
}

header .header-left .logo h2 {
  width: 100%;
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 15px;
  margin-bottom: 0;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  animation: glow 3500ms linear infinite 2000ms;
  left: 20px;
  line-height: 1.5;
}

header .header-right {
  margin: 20px 0px;
  float: right;
  align-items: center;
}

header .header-right i.fa {
  display: inline-block;
  border-radius: 50px;
  padding: 0.5em 0.5em;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background: #4cb66d;
  margin: 0 10px;
  position: relative;
}

header .header-right i.fa::before {
  color: #fff;
  font-size: 19px;
}

.number-notify {
  margin: 0;
  line-height: 15px;
  content: "20";
  position: absolute;
  height: 15px;
  top: -0.2rem;
  right: -0.8rem;
  min-width: 28px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  background: #f55d5d;

}

.wrap-notify.show .button-notify::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: -16px;
  transform: translateX(-50%);
  border-bottom: 9px solid #fff;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  z-index: 999;
}

.wrap-notify {
  margin-right: 20px;
}

.button-notify {
  border: none;
  background: transparent;
  outline: 0;
  padding: 0;
}

.button-notify:focus {
  border: none;
  background: transparent;
  outline: 0;
  box-shadow: none;
}

.content-list-notify .title {
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: 550;
}

.dropdown-menu-arrow {
  border-top: none;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  margin-top: 15px;
}

/* width */
.content-list-notify::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.content-list-notify::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-list-notify::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.content-list-notify::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-list-notify {
  padding: 15px 10px;
  border: 0;
  min-width: 410px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  min-height: 150px;
  max-height: 250px;
}

.child-notify {
  transition: 0.2s;
  padding: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: rgb(247, 247, 247);
  margin-bottom: 5px;
}

.child-notify.new-notify {
  background: rgb(234 247 218);
}

.child-notify:hover {
  background: #e3e3e3;
  cursor: pointer;
  transform: scale(1.03);
}

.child-notify.new-notify:hover {
  background: #44ad1e;
  color: white;
}

.child-notify .icon {
  font-size: 25px;
}

.child-notify .text-time {
  padding-left: 10px;
}

.child-notify .text {
  white-space: normal;
  font-size: 14px;
}

.child-notify .notify-time {
    margin-left: 250px;
}

.child-notify .time {
  font-size: 12px;
}

.wrap-notify .dropdown-toggle::after {
  display: none;
}

.wrap-slogan {
  display: flex;
}

.slogan-1, .slogan-2 {
  overflow:hidden;
}

.slogan-1 {
  animation: showup 10s infinite;
}

.slogan-2 {
  width:0px;
  animation: reveal 10s infinite;
}

.slogan-2 span {
  margin-left:-355px;
  animation: slidein 10s infinite;
}
.header-left.required-header {
    pointer-events: none;
    cursor: not-allowed;
}
.header-right .required-profile{
    pointer-events: none;
    cursor: not-allowed;
}
@keyframes showup {
  0% {opacity:0;}
  20% {opacity:1;}
  80% {opacity:1;}
  100% {opacity:0;}
}

@keyframes slidein {
  0% { margin-left:-800px; }
  20% { margin-left:-800px; }
  35% { margin-left:0px; }
  100% { margin-left:0px; }
}

@keyframes reveal {
  0% {opacity:0;width:0px;}
  20% {opacity:1;width:0px;}
  30% {width:calc(100% - 61px);}
  90% {opacity:1;}
  100% {opacity:0;width:calc(100% - 61px);}
}

@keyframes glow {
    40% {
        text-shadow: 0 0 8px #fff;
    }
}

@media screen and (max-width: 768px) {
  header .profile {
    justify-content: unset;
  }
}

@media (max-width: 500px) {
  header .header-right {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .content-list-notify {
    min-width: 370px;
  }

  header .form-select-language {
    transition: 0.7s;
    margin-bottom: 10px;
  }
}

.go-to-info, .go-to-info:hover {
  color: black;
  text-decoration: none;
}
.break-spaces {
  white-space: break-spaces;
}
.btn-music {
  position: absolute;
  right: 145px;
  bottom: 19px;
  z-index: 99;
  cursor: pointer;
}
.btn-music-en {
  right: 90px
}
.btn-music-esp {
  right: 80px
}
.btn-music:hover {
  filter: hue-rotate(86deg) brightness(118%) contrast(119%);
}
.float-animation {
  animation: float 4s ease-in-out infinite;
}
@keyframes float {
  0% {
    /*box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);*/
    transform: translatey(0px);
  }
  25% {
    /*box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);*/
    transform: translatey(-10px);
  }

  75% {
    /*box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);*/
    transform: translatey(10px);
  }
  100% {
    /*box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);*/
    transform: translatey(0px);
  }
}
.highlight-focus:hover {
  text-decoration: underline;
}
