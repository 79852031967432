.profile .parent-img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.profile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.profile #user-name {
  font-weight: bold;
  margin-left: 10px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.profile #user-name p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profile .dropdown-toggle::after {
  display: none;
}

.profile .dropdown-menu {
  top: 10px !important;
  animation-name: effectBlock;
  animation-duration: 0.7s;
}

.profile.dropdown .dropdown-menu {
  min-width: 110%;
  width: auto !important;
}

.profile .dropdown-menu .dropdown-item {
  margin: 0;
  cursor: pointer;
}

.profile .dropdown-menu .dropdown-item:active a {
  color: white !important;
}

.profile .dropdown-menu .dropdown-item:active {
  background: #168334;
}

@keyframes effectBlock {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
